import { ReusableComponentProps } from '@packfleet/ui';
import cs from 'classnames';
import NextLink, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

type Props = ReusableComponentProps &
  LinkProps & {
    target?: string;
    children?: ReactNode;
  };

export const Link = ({
  target,
  className,
  style,
  href,
  children,
  unstyled,
  ...props
}: Props) => {
  let classNames = className;
  if (!unstyled) {
    classNames = cs(classNames, 'text-primary underline hover:no-underline');
  }
  return (
    <NextLink
      href={href}
      passHref={true}
      className={classNames}
      style={style}
      target={target}
      {...props}
    >
      {children}
    </NextLink>
  );
};
