import { BrandingFragment } from '@/generated/graphql';

const DEFAULT_BACKGROUND_IMAGE = '/pf-gradient.jpg';
const DEFAULT_BRANDING: Omit<BrandingFragment, 'id'> = {
  isDemoBranding: false,
  // TODO: Consider bringing into tailwind.config.js as config matures
  colors: {
    background: '#f1f3f5',
    text: '#003846',

    primary: '#00ADC5',
    secondary: '#008C73',

    backgroundPrimary: '#EDEFFD',
    backgroundSecondary: '#008C73',

    textButtonPrimary: '#FFFFFF',
    backgroundButtonPrimary: '#008C73',

    textButtonSecondary: '#008C73',
    backgroundButtonSecondary: '#DDF3F0',
  },
  images: {
    bodyBackground: {
      url: DEFAULT_BACKGROUND_IMAGE,
      // Width and height are currently unused
      width: 0,
      height: 0,
    },
  },
};

export function useBranding(branding: BrandingFragment | null | undefined) {
  const {
    isDemoBranding,
    images: _images,
    colors,
  } = branding ?? DEFAULT_BRANDING;

  const fallbackBackgroundImage =
    // If we're using the default background color
    // and there is no custom background image set,
    // then use the default background image
    !_images.bodyBackground &&
    branding?.colors?.background === DEFAULT_BRANDING.colors.background
      ? DEFAULT_BRANDING.images.bodyBackground
      : null;

  const images = {
    ..._images,
    bodyBackground: _images.bodyBackground
      ? _images.bodyBackground
      : fallbackBackgroundImage,
    bodyBackgroundMobile: _images.bodyBackgroundMobile
      ? _images.bodyBackgroundMobile
      : fallbackBackgroundImage,
  };

  const alignContent: 'right' | undefined =
    (images.bodyBackground &&
      images.bodyBackground.url !== DEFAULT_BACKGROUND_IMAGE) ||
    images.backgroundCenter
      ? 'right'
      : undefined;

  return {
    isDemoBranding,
    images,
    colors,
    alignContent,
  };
}
