import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apolloConfig = (link: ApolloLink): ApolloClientOptions<any> => {
  return {
    ssrMode: typeof window === 'undefined', // set to true for SSR
    connectToDevTools: true,
    link: link,
    cache: new InMemoryCache({
      possibleTypes: {
        ThirdPartyAppSession: [
          'LinnworksSession',
          'ShopifyShop',
          'WooCommerceSession',
        ],
        ReadOrderTrackingInfoResponse: [
          'ReadTrackingInfoPayload',
          'ReadExternalTrackingInfoPayload',
          'ReadOrderTrackingInfoPayload',
        ],
      },
    }),
  };
};
