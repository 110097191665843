import { BrandingColors } from '@/generated/graphql';
import { hexToRgb } from '@/utilities/colors';
import React from 'react';

export const Branding = ({ colors }: { colors: BrandingColors }) => {
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        :root {
          --color-brand-body: ${hexToRgb(colors.background)};
          --color-brand-text: ${hexToRgb(colors.text)};

          --color-brand-text-primary: ${hexToRgb(colors.primary)};
          --color-brand-background-primary: ${hexToRgb(
            colors.backgroundPrimary,
          )};

          --color-brand-text-secondary: ${hexToRgb(colors.secondary)};
          --color-brand-background-secondary: ${hexToRgb(
            colors.backgroundSecondary,
          )};

          --color-brand-button-text-primary: ${hexToRgb(
            colors.textButtonPrimary,
          )};
          --color-brand-button-background-primary: ${hexToRgb(
            colors.backgroundButtonPrimary,
          )};

          --color-brand-button-text-secondary: ${hexToRgb(
            colors.textButtonSecondary,
          )};
          --color-brand-button-background-secondary: ${hexToRgb(
            colors.backgroundButtonSecondary,
          )};
        }
        `,
      }}
    />
  );
};
