import cs from 'classnames';
import React from 'react';

import { Icons } from '@/components/Icons';
import { Link } from '@/components/link/Link';

export type Props = {
  className?: string;
  hideLogo?: boolean;
  alignContent?: 'center' | 'right';
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = ({
  className,
  hideLogo,
  children,
  alignContent = 'center',
  ...props
}: Props) => {
  return (
    <div className="mx-auto w-full flex flex-col justify-center" {...props}>
      <div className="w-100 flex justify-center pb-7 pt-10">
        {!hideLogo && (
          <Link
            href="https://packfleet.com"
            target="_blank"
            className="flex justify-center opacity-40"
          >
            <Icons.packfleet width={116} height={31} />
          </Link>
        )}
      </div>
      <div
        className={cs(
          'mx-auto overflow-hidden lg:bg-gradient-to-l from-[var(--color-body)] to-transparent via-[var(--color-body)]',
          { 'lg:mr-0 lg:pr-20': alignContent === 'right' },
          className,
        )}
      >
        <div className="pb-36 px-4 w-full">{children}</div>
      </div>
    </div>
  );
};
