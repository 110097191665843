import { Link } from '@/components/link/Link';
import { BaseButton, BaseButtonProps } from '@packfleet/ui';
import React, { FunctionComponent } from 'react';

export type Props = BaseButtonProps<HTMLAnchorElement> & {
  href: string;
  component?: React.ElementType | keyof JSX.IntrinsicElements;
};

export const LinkButton: FunctionComponent<Props> = ({
  children,
  href,
  component = Link,
  ...props
}) => {
  return (
    <BaseButton
      component={component}
      componentProps={{ unstyled: true }}
      href={href}
      {...props}
    >
      {children}
    </BaseButton>
  );
};
