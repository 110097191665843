import { Branding } from '@/components/branding/Branding';
import { HeadTag, Meta } from '@/components/head-tag/HeadTag';
import { BrandingFragment, PackStatus } from '@/generated/graphql';
import { useBranding } from '@/hooks/useBranding';
import { Inter } from '@next/font/google';
import cs from 'classnames';
import { motion } from 'framer-motion';
import Image from 'next/image';
import React, { ReactNode } from 'react';

export type Props = {
  title: string;
  meta?: Meta;
  robotsIndexEnabled?: boolean;
  children?: ReactNode;
  className?: string;
  deliveryStage?: PackStatus;
  branding?: BrandingFragment | null;
};

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
});

export const Page = ({
  title,
  children,
  className,
  meta = {},
  robotsIndexEnabled = false,
  deliveryStage,
  branding,
}: Props) => {
  const { images, colors, alignContent } = useBranding(branding);
  const isFailed = deliveryStage === PackStatus.FailedDelivery;

  return (
    <>
      <HeadTag
        title={title}
        meta={meta}
        robotsIndexEnabled={robotsIndexEnabled}
      >
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
      </HeadTag>
      <Branding colors={colors} />
      <div
        className={cs(
          'min-h-[100%] w-full flex-col md:flex-row md:pt-0',
          inter.className,
        )}
      >
        <div className="bg-brandBody inset-0 z-0 pointer-events-none fixed" />
        <div
          className={cs('relative', {
            '3xl:max-w-[1920px] 3xl:mx-auto': alignContent === 'right',
          })}
        >
          {images.bodyBackgroundMobile ? (
            <div
              className="pointer-events-none fixed inset-0 z-0 bg-opacity-brandBackgroundSecondary lg:hidden"
              style={{
                filter: isFailed ? 'saturate(0)' : undefined,
                top: images.bodyBackgroundMobile.top ?? undefined,
              }}
            >
              <Image
                src={images.bodyBackgroundMobile.url}
                fill
                className="object-cover object-top"
                alt="Body background image"
              />
            </div>
          ) : null}

          {images.bodyBackground ? (
            <div
              className={cs(
                'pointer-events-none fixed inset-0 z-0 bg-opacity-brandBackgroundSecondary hidden lg:block',
              )}
              style={{
                filter: isFailed ? 'saturate(0)' : undefined,
              }}
            >
              <Image
                src={images.bodyBackground.url}
                fill
                className="object-cover object-top"
                alt="Body background image"
              />
            </div>
          ) : null}

          {images.backgroundLeft ? (
            <div className="absolute left-1/2 -translate-x-[calc(50%+350px)] top-[220px] flex lg:hidden cursor-pointer">
              <div className="w-[510px] h-[576px] relative mx-auto rounded-xl overflow-hidden">
                <Image
                  src={images.backgroundLeft.url}
                  fill
                  alt="Branded background image"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          ) : null}

          {images.backgroundRight ? (
            <div className="absolute left-1/2 -translate-x-[calc(50%-224px)] top-[630px] flex lg:hidden cursor-pointer">
              <div className="w-[168px] h-[256px] relative mx-auto rounded-xl overflow-hidden">
                <Image
                  src={images.backgroundRight.url}
                  fill
                  alt="Branded background image"
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          ) : null}

          {images.backgroundCenter ? (
            <div className="fixed right-[calc(45%+210px)] top-1/2 translate-x-1/2 -translate-y-1/2 lg:flex hidden cursor-pointer z-0">
              <div className="w-[360px] h-[310px] relative mx-auto max-w-[20vw]">
                <Image
                  src={images.backgroundCenter.url}
                  fill
                  alt="Branded background image"
                  style={{ objectFit: 'contain' }}
                />
              </div>
            </div>
          ) : null}

          {images.product1 ? (
            <div className="fixed right-full translate-x-[10vw] translate-y-[14vh] lg:flex hidden cursor-pointer 3xl:-translate-x-[1100px] 3xl:left-1/2">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 1000,
                  damping: 120,
                  delay: 1,
                }}
              >
                <div className="w-[292px] h-[375px] relative mx-auto rounded-xl overflow-hidden">
                  <Image
                    src={images.product1.url}
                    fill
                    alt="Product shot"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </motion.div>
            </div>
          ) : null}

          {images.product2 ? (
            <div className="fixed right-0 top-0 -translate-x-[calc(440px+2vw)] -translate-y-[14vh] lg:flex hidden cursor-pointer 3xl:left-1/2 3xl:translate-x-[100px]">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 1000,
                  damping: 120,
                  delay: 1.2,
                }}
              >
                <div className="w-[360px] h-[240px] relative mx-auto rounded-xl overflow-hidden">
                  <Image
                    src={images.product2.url}
                    fill
                    alt="Product shot"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </motion.div>
            </div>
          ) : null}

          {images.product3 ? (
            <div className="fixed right-[calc(50%+210px)] top-full translate-x-[25vw] -translate-y-[20vh] lg:flex hidden cursor-pointer 3xl:left-1/2 3xl:translate-x-[130px]">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  type: 'spring',
                  stiffness: 1000,
                  damping: 120,
                  delay: 1.4,
                }}
              >
                <div className="w-[234px] h-[290px] relative mx-auto rounded-xl overflow-hidden">
                  <Image
                    src={images.product3.url}
                    fill
                    alt="Product shot"
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </motion.div>
            </div>
          ) : null}
          <main className={cs('min-h-[100vh] relative flex w-full', className)}>
            {children}
          </main>
        </div>
      </div>
    </>
  );
};
