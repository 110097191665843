// TODO: Share utils with merchant
import urlcat from 'urlcat';

export enum Routes {
  home = '/',
  login = '/login',
  tracking = '/tracking/',
  trackingDetail = '/tracking/:trackingNumber',
  confirmDetails = '/tracking/:trackingNumber/confirm-details',
  externalTrackingDetail = '/external-tracking/:canonicalName/:carrierCode/:trackingNumber',

  apiLogout = '/internal-api/auth/session-logout',
  apiLogin = '/internal-api/auth/session-login',
}

export const PUBLIC_APP_DOMAIN =
  process.env.NEXT_PUBLIC_BASE_URL ??
  process.env.NEXT_PUBLIC_VERCEL_URL ??
  'localhost:3020';

export function route(
  path: Routes,
  params: { [key: string]: string | string[] | undefined } = {},
) {
  return urlcat('', path, params);
}

export function constructURL(
  domain: string,
  path: string,
  params?: URLSearchParams,
) {
  let protocol = 'https';
  if (domain.startsWith('localhost')) {
    protocol = 'http';
  }
  if (params == null) {
    return `${protocol}://${domain}/${path}`;
  }
  return `${protocol}://${domain}/${path}?${params.toString()}`;
}
