import { Temporal } from '@js-temporal/polyfill';
import { Locale, format, setDefaultOptions } from 'date-fns';
import * as Locales from 'date-fns/locale';

export function initializeDateFns() {
  setDefaultOptions({
    locale: getDateFnsLocale(),
  });
}

export function getDateFnsLocale(localeString?: string): Locale {
  // If not included in the function arguments default to the browsers locale
  localeString = localeString ?? Intl.NumberFormat().resolvedOptions().locale;

  const locale = localeString.substring(0, 2);
  const region = localeString.substring(3, 5);

  return (
    Locales[(locale + region) as keyof typeof Locales] ??
    Locales[locale as keyof typeof Locales] ??
    Locales.enGB
  );
}

export function formatTimeRange(
  startTime?: string | null,
  endTime?: string | null,
  abbreviate?: boolean,
) {
  return (
    [
      startTime && endTime
        ? abbreviate
          ? ''
          : 'Between '
        : endTime
          ? 'Before '
          : startTime
            ? 'After '
            : '',
      startTime,
      startTime && endTime ? (abbreviate ? ' to ' : ' and ') : null,
      endTime,
    ].join('') || 'Any time'
  );
}

export function formatTime(d: Date): string {
  return format(d, 'HH:mm');
}

export function formatIntlDate(
  date: Temporal.PlainDate,
  timezone: string,
  showYear = false,
) {
  return date.toZonedDateTime(timezone).toLocaleString('default', {
    weekday: 'long',
    month: 'long',
    year: showYear ? 'numeric' : undefined,
    day: 'numeric',
  });
}
